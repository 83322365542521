import 'htmx.org'
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/offcanvas.js';
import Swiper from 'swiper';
import AOS from 'aos'
import { Navigation, Pagination } from 'swiper/modules';
import Alpine from 'alpinejs'

AOS.init({once: true})
Swiper.use([Navigation, Pagination]);
window.Swiper = Swiper
window.Alpine = Alpine

// Start Alpine.
Alpine.start()